import React from 'react'
import { Route, Redirect, Switch } from 'react-router'
import HomePage from './view/home/HomePage'
import { BillOfMaterialsPage } from './view/sourceData/BillOfMaterialsPage'
import { ForecastPage } from './view/sourceData/ForecastPage'
import { InventoryPage } from './view/sourceData/InventoryPage'
import { ProductionActualsPage } from './view/sourceData/ProductionActualsPage'
import DeliveriesPage from './view/sourceData/DeliveriesPage'
import SourceDataPage from './view/sourceData/SourceDataPage'
import KaiPage from './view/kai/KaiPage'
import NavbarPrimary from './view/navbar/NavbarPrimary'
import NavbarSource from './view/navbar/NavbarSource'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios';

const Routes = () => {
  // const { location } = useHistory()
  // const pathName = location.pathname
  // const onSourcePage = pathName.includes('source')

  const { user } = useAuth0();
  axios.defaults.headers.common['Authorization'] = user?.email;

  return (
    <>
      <NavbarPrimary />
      <NavbarSource />
      <Switch>
        <Route exact path='/'>
          <Redirect to='/home' />
        </Route>
        <Route exact path='/home' component={HomePage} />
        <Route exact path='/source-data' component={SourceDataPage} />
        <Route exact path='/source-data/deliveries' component={DeliveriesPage} />
        <Route exact path='/source-data/bill' component={BillOfMaterialsPage} />
        <Route exact path='/source-data/forecast' component={ForecastPage} />
        <Route exact path='/source-data/inventory' component={InventoryPage} />
        <Route exact path='/source-data/productionActual' component={ProductionActualsPage} />
        <Route exact path='/kai' component={KaiPage} />
      </Switch>
    </>
  )
}

export default Routes;
