import React, { useState } from 'react'
import { Box, Chip, Collapse, Container, IconButton, makeStyles, Menu, MenuItem, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useGetAgentActions, usePatchAgentAction } from '../../hooks/queries/kaiAgent';
import { TabPanel } from '@material-ui/lab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const KaiPage = () => {
    return (
        <section className='bg-light py-5'>
            <Container>
                <ActionTable />
            </Container>
        </section>
    )
}

function ActionTable() {
    const [actions, setActions] = useState([]);
    const [statusType, setStatusType] = useState<'ALL' | 'PENDING_CONSENT' | 'PENDING_RESPONSE' | 'CLOSED'>('ALL');

    const { refetch, isFetching } = useGetAgentActions('kai-actions', setActions);

    const [showMoreView, setShowMoreView] = useState<number | null>(null);
    const { mutateAsync: mutateAction } = usePatchAgentAction()
    function toggleShowMoreView(index: any) {
        if (showMoreView == index) {
            setShowMoreView(null);
        } else {
            setShowMoreView(index);
        }
    }

    async function updateActionAndRefreshData({ id, status }) {
        const res = await mutateAction({ id, status })
        await refetch();
        return res;
    }

    return <div style={{ padding: 20, borderRadius: 15, boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)', backgroundColor: 'white' }} className='rounded-lg' >
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 24 }}>
            <Typography component='p'>Status:</Typography>
            <Tabs value={statusType} onChange={(_, v) => setStatusType(v)}>
                <Tab value="ALL" label="All" />
                <Tab value="PENDING_CONSENT" label="Pending Consent" />
                <Tab value="PENDING_RESPONSE" label="Pending Response" />
                <Tab value="UNRESOLVED" label="Unresolved" />
                <Tab value="CLOSED" label="Closed" />
            </Tabs>
        </div>
        <TableContainer component={Paper} elevation={0}>
            <Table key={`action_table_${showMoreView}`}>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Action</TableCell>
                        <TableCell>Part(s)</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {actions.filter((a: any) => statusType == 'ALL' || statusType == a.status)?.map((d, i) => (
                        <Row key={`action_row_${i}`} action={d} toggleShowMoreView={toggleShowMoreView} showMoreView={showMoreView} mutateAction={updateActionAndRefreshData} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        {isFetching && 'Loading...'}
    </div>
}

interface AgentActivityTableRowProps {
    action: {
        id: string;
        part_numbers: string[];
        action_type: string;
        status: string;
        data: {
            subject: string;
            recipients: string[];
            riskLevel: string;
            body: string;
        }
    }
    showMoreView: any;
    toggleShowMoreView: (index: any) => any;
    mutateAction: any;
}

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function Row({ action, showMoreView, toggleShowMoreView, mutateAction }: AgentActivityTableRowProps) {
    const i = action.id;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [bodyOpen, setBodyOpen] = useState(false);
    const classes = useRowStyles();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = async () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = async ({ id, status }) => {
        setAnchorEl(null);
        const res = await mutateAction({ id, status });
    };
    console.log({ bodyOpen });

    function ActionCellHeading() {
        if (action.action_type === 'UPDATE_DELIVERY') {
            return <>
                Update Delivery
            </>
        }

        return <>
            {action.data.subject}
            <br />
            <span style={{ color: 'grey' }}>To: {action.data.recipients?.[0]}</span>
        </>
    }

    function ActionCellBody() {
        if (action.action_type === 'UPDATE_DELIVERY') {
            return <div>
                {Object.keys(action.data).map(k => <>{k}: {action.data[k] ?? 'None'} <br /></>)}
                <br />
            </div>
        }

        return <p>
            <div dangerouslySetInnerHTML={{ __html: action.data.body }}></div>
            <br />
        </p>
    }

    function viewMoreButtonClicked() {
        setBodyOpen(!bodyOpen);
    }

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton size='small' onClick={viewMoreButtonClicked}>
                        {bodyOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <ActionCellHeading />
                </TableCell>
                <TableCell>{action['part_numbers'].slice(0, 2).join(', ')}{action['part_numbers'].length > 2 ? `, ${action['part_numbers'].length - 2}+` : ''}</TableCell>
                <TableCell><Chip size='small' style={chipStyleForStatus(action.status as any)} variant='outlined' label={formatStatus(action.status)} /></TableCell>
                <TableCell width={65}>
                    <IconButton disabled={action.status !== 'PENDING_CONSENT'} size='small' onClick={handleMenuClick} color="secondary">
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleMenuClose}
                        elevation={1}
                    >
                        <MenuItem key={`menu-item-action-${i}-approve`} value="Approved" onClick={() => handleMenuItemClick({ id: action.id, status: "Approved" })}>Approve</MenuItem>
                        <MenuItem key={`menu-item-action-${i}-decline`} value="Declined" onClick={() => handleMenuItemClick({ id: action.id, status: "Declined" })}>Decline</MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={bodyOpen} timeout="auto" unmountOnExit>
                        <Box margin='auto' maxWidth={800} marginTop={2}>
                            <ActionCellBody />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const formatStatus = (input) => {
    return input
        .split('_')
        .map(word =>
            word.charAt(0).toUpperCase() +
            word.slice(1).toLowerCase()
        )
        .join(' ');
};

function chipStyleForStatus(status: 'PENDING_CONSENT' | 'PENDING_RESPONSE' | 'CLOSED' | 'UNRESOLVED' ) {
    if (status === 'PENDING_CONSENT') {
        return { color: '#d08700', borderColor: '#d08700', backgroundColor: '#FEFFE5' } // yello
    } else if (status === 'PENDING_RESPONSE') {
        return { color: '#0084d1', borderColor: '#0084d1', backgroundColor: '#eff8ff' } // blue
    } else if (status === 'CLOSED') {
        return { color: '#009966', borderColor: '#009966', backgroundColor: '#F0FFF8' } // green
    } else if (status === 'UNRESOLVED') {
        return { color: 'red', borderColor: 'red', backgroundColor: '#FFF0F0' }
    }
    return {};
}

export default KaiPage;