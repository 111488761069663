import { useQuery, useMutation } from 'react-query';
import { rqDefaultOptions } from './defaultOptions';
import { API_URL } from '../../config';
import axios from 'axios';

const getAgentActions = async (inputObj) => {
    const sourceSummaryAPIPath = `${API_URL}/api/kai/v1/actions`;
    const res = await axios.get(sourceSummaryAPIPath);
    return res.data;
}

const patchAgentAction = async ({ id, status }) => {
    const sourceSummaryAPIPath = `${API_URL}/api/kai/v1/actions`;
    return await axios.patch(sourceSummaryAPIPath, { id, status })
}

export function useGetAgentActions(queryKey, setFn) {
    const options = {
        onSuccess: (data) => {
            setFn(data);
        },
        ...rqDefaultOptions,
        refetchOnWindowFocus: false,
        enabled: true
    }
    return useQuery([queryKey], getAgentActions, options)
}

export function usePatchAgentAction(){
    return useMutation(patchAgentAction);
}
