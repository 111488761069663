import React from 'react'
import { Button, Container, Drawer, IconButton } from '@material-ui/core'
import { Link, NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { Typography } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { deepPurple } from '@material-ui/core/colors'
import { grey } from '@material-ui/core/colors'
import { useAuth0 } from '@auth0/auth0-react'


const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 6,
    height: 48,
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    fontSize: 30,
    height: 55,
    margin: '0 auto',
    width: 55,
  },
  center: {
    margin: '0 auto',
  },
  textBold: {
    fontWeight: 600,
  },
}))

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: 15,
    padding: 8,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      background: 'none',
      cursor: 'default',
    },
    '&:hover': {
      background: 'none',
      cursor: 'default',
    },
  },
}))(MenuItem)

const LogoutButton = withStyles((theme) => ({
  root: {
    borderRadius: 20,
    color: theme.palette.getContrastText(grey[800]),
    backgroundColor: grey[800],
    '&:hover': {
      backgroundColor: grey[900],
    },
  },
}))(Button)

const NavbarPrimary = () => {
  const classes = useStyles()
  const { logout, user } = useAuth0()

  const [drawer, setDrawer] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  let name = ''
  let email = ''
  if (user) {
    name = user.name
    email = user.email
  }

  return (
    <>
      <header className={clsx('navbar-container bg-primary position-sticky fixed-top', classes.header)}>
        <Container maxWidth='md'>
          <section className='row align-items-center mx-4'>
            <div className='d-block d-lg-none'>
              <Button onClick={() => setDrawer(true)}>
                <MenuIcon className='menu-icon' />
              </Button>
            </div>
            <ul className='nav justify-content-around d-none d-lg-flex'>
              <li className='nav-item'>
                <NavLink className='nav-link' exact to='/home'>
                  <Typography className={classes.textBold}>Home</Typography>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' to='/source-data'>
                  <Typography className={classes.textBold}>Source Data</Typography>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' to='/kai'>
                  <Typography className={classes.textBold}>Kai</Typography>
                </NavLink>
              </li>
              {/* <li className='nav-item'>
                <NavLink className='nav-link' exact to='/parts'>
                  Parts
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' exact to='/scenario-planning'>
                  Scenario Planning
                </NavLink>
              </li> */}
            </ul>

            <ul className='nav ml-auto mb-2 mb-md-0'>
              {/* <li className=''>
                <Link className='icon-hover' to='/home'>
                  <IconButton>
                    <NotificationsActiveIcon style={{ color: 'white' }} />
                  </IconButton>
                </Link>
              </li>
              <li className=''>
                <Link className='icon-hover' to='/home'>
                  <IconButton>
                    <SettingsIcon style={{ color: 'white' }} />
                  </IconButton>
                </Link>
              </li> */}
              <li className=''>
                <IconButton className='icon-hover' aria-controls='customized-menu' aria-haspopup='true' onClick={handleOpenMenu}>
                  <AccountCircleIcon style={{ color: 'white' }} />
                </IconButton>
                <StyledMenu id='customized-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                  <StyledMenuItem disableRipple>
                    <Avatar className={classes.purple}>{name.charAt(0)}</Avatar>
                  </StyledMenuItem>
                  <StyledMenuItem disableRipple>
                    <Typography className={classes.center}>{name}</Typography>
                  </StyledMenuItem>
                  <StyledMenuItem disableRipple>
                    <Typography className={classes.center}>{email}</Typography>
                  </StyledMenuItem>
                  <StyledMenuItem disableRipple>
                    <LogoutButton
                      variant='contained'
                      onClick={() => logout({ returnTo: `${window.location.origin}/home` })}
                      color='primary'
                      className={classes.center}
                    >
                      Log Out
                    </LogoutButton>
                  </StyledMenuItem>
                </StyledMenu>
              </li>
            </ul>
          </section>
        </Container>
      </header>
      <Drawer open={drawer} onClose={() => setDrawer(false)}>
        <ul className='nav flex-column p-4'>
          <li className='nav-item'>
            <NavLink className='nav-link' exact to='/'>
              <Typography>Home</Typography>
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className='nav-link' exact to='/source-data'>
              <Typography>Source Data</Typography>
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className='nav-link' exact to='/kai'>
              <Typography>Kai</Typography>
            </NavLink>
          </li>
        </ul>
      </Drawer>
    </>
  )
}

export default NavbarPrimary
