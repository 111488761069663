import React, { useState } from 'react'

import ForecastSourceData from '../sections/ForecastSourceData'
import ForecastTable from './ForecastTable'
import SourceAccordians from './SourceAccordian/SourceAccordians'
import { useForecastTableData } from '../../../hooks/queries/forecast'
import { useForecastSourceSummary } from '../../../hooks/queries/sourceSummary'
import { useQueryClient } from 'react-query'
import Loading from '../../../components/Loading'

export const SingleForecast = ({ forecast, resetProductList }) => {
  const [sheetData, setSheetData] = useState({})
  const [expanded, setExpanded] = useState(false);

  const sourceSummaryQueryKey = 'forecastSourceSummary'
  const sourceSummaryType = 'forecastSummary'
  const dataSummaryQueryKey = 'forecastTable'

  const { isLoading: iLForecastTable, isError: iEForecastTable, data: dataForecastTable } = useForecastTableData(dataSummaryQueryKey, forecast?.dataSourceId, 1000, 0)

  const { isFetching: iFSourceData } = useForecastSourceSummary(sourceSummaryQueryKey, sourceSummaryType, forecast?.dataSourceId, {
    setSheetData,
  })

  const queryClient = useQueryClient()
  const refetchData = (selectNewForecast: boolean) => {
    queryClient.refetchQueries([sourceSummaryQueryKey])
    queryClient.refetchQueries([dataSummaryQueryKey])
    if (selectNewForecast) {
      resetProductList();
    }
  }

  const refreshTableData = () => {
    queryClient.refetchQueries([dataSummaryQueryKey])
  }

  const clearSheetData = () => {
    setSheetData({});
    resetProductList();
  }

  const setExpand = (Val) => {
    setExpanded(Val);
  }

  const sourceDataSources = iFSourceData ? [] : [<ForecastSourceData refetchData={refetchData} uploadSummary={sheetData} key={0} setExpand={setExpand} />]

  const tableData = iLForecastTable || iEForecastTable ? [] : dataForecastTable

  return (
    <>
      {iFSourceData ? (
        <Loading />
      ) : (
        <SourceAccordians
          label={'Forecast'}
          refetchData={refetchData}
          clearSheetData={clearSheetData}
          sources={sourceDataSources}
          displayComp={<ForecastTable dataSourceId={forecast?.dataSourceId} title='Forecast Table' data={tableData} refreshTableData={refreshTableData} />}
          addNewSource={false}
          toExpand={expanded}
        />
      )}
    </>
  )
}
